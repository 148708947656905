var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-chat","title":_vm.$tc('Views.cob-1'),"loading":_vm.loader}}),_c('v-row',{class:_vm.bills.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$tc('Views.cob-2')}}):_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('bar-search',{attrs:{"search":_vm.search,"readonly":_vm.loadingDT,"searchCallback":_vm.searchBillByID,"label":_vm.$tc('Views.bidc-1')},on:{"update:search":function($event){_vm.search=$event}}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"customDT",attrs:{"id":"bill-comments-table","headers":_vm.headers,"items":_vm.bills,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
            itemsPerPageText: _vm.$tc('Views.cob-3'),
            pageText: ("{0}-{1} " + (_vm.$tc('Newsletter.newslettersByPage')) + " {2}"),
            'items-per-page-all-text': _vm.$tc('Newsletter.allNewsletters'),
          },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Views.cob-4"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Views.cob-5")))])]},proxy:true},{key:"item.client",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.email)+" ")]}},{key:"item.bill_id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('BillChat',{attrs:{"bill_id":item.id,"is_icon":true,"disabled":item.client.id == _vm.userInfo.id}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }